import React, { useEffect, useState } from "react";
import "./TelegramSendMess.css";

function TelegramSendMess({ catalog }) {
  const TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;
  const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");

  const [nameDirty, setNameDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [nameError, setNameError] = useState("Имя не может быть пустым");
  const [phoneError, setPhoneError] = useState("Телефон не может быть пустым");
  const [modalVisible, setModalVisible] = useState(false);

  const nameMandler = (e) => {
    const value = e.target.value.trim();
    setName(value);

    if (!value) {
      setNameError("Имя не может быть пустым");
    } else if (!/^[а-яёa-z\s]+$/i.test(value)) {
      // Добавлен пробел в регулярное выражение
      setNameError("Имя может содержать только буквы и пробелы");
    } else {
      setNameError("");
    }
  };

  const numberMandler = (e) => {
    setPhone(e.target.value);
    const re = /^\+?[78][-\(]?[7]\d{2}\)?-?\d{3}-?\d{2}-?\d{2}$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setPhoneError("Некорректный номер телефона");
    } else {
      setPhoneError("");
    }
  };

  const [formValid, setFormValid] = useState(false);
  useEffect(() => {
    if (phoneError || nameError || !name || !phone) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [phoneError, nameError, name, phone]);

  const btn = () => {
    fetch(URL_API, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chat_id: CHAT_ID,
        text: `<b>Новый заказ!</b> \n <b>Имя: </b>${name} \n <b>Номер: </b>${phone} \n
       <b>Выбранные товары:</b> \n  ${catalog?.map(
         (el, i) => `<b>Товар ${i + 1}: </b>` + el.title + `\n \n`
       )}`,
        parse_mode: "html",
      }),
    })
      .then((res) => {
        if (res.ok) {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            setName("");
            setPhone("");
            setNameDirty(false);
            setPhoneDirty(false);
            setFormValid(false);
          }, 700);
        } else {
          console.error("Ошибка при отправке сообщения:", res.status);
        }
      })
      .catch((error) => {
        console.error("Ошибка при отправке сообщения:", error);
      });
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
    }
  };

  return (
    <div className="form_bot">
      <h3 className="form_bot_title">
        Оставьте свои данные и мы с вами свяжимся
      </h3>
      <form action="" className="form_bot_main">
        <label htmlFor="">
          <h4>Напишите свое имя</h4>
          <input
            value={name}
            onChange={(e) => nameMandler(e)}
            type="text"
            name="name"
            placeholder="Ваше имя"
            onBlur={(e) => blurHandler(e)}
          />
          {nameDirty && nameError && (
            <div className="errorForm">{nameError}</div>
          )}
        </label>
        <label htmlFor="">
          <h4>Введите ваш телефон</h4>
          <input
            value={phone}
            onChange={(e) => numberMandler(e)}
            type="text"
            name="phone"
            id=""
            placeholder="+7 (705) 880-68-68"
            onBlur={(e) => blurHandler(e)}
          />
          {phoneDirty && phoneError && (
            <div className="errorForm">{phoneError}</div>
          )}
        </label>
        <button disabled={!formValid} type="button" onClick={btn}>
          Отправить
        </button>
      </form>
      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <p>Ваше сообщение было успешно отправлено!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default TelegramSendMess;
