import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";
import Filter from "../Filter/Filter";
import Header from "../Header/Header";
import ProductsItem from "../ProductsItem/ProductsItem";
import "./Category.css";
import ClipLoader from "react-spinners/ClipLoader";

function CategoryType() {
  const [check, setCheck] = useState(false);
  const { all_product, loading } = useContext(ShopContext);
  const { categoryId, categoryType } = useParams();
  const [products, setProducts] = useState([]);
  const [productType, setProductType] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      // Фильтрация продуктов при изменении categoryId и categoryType
      setProducts(
        all_product.filter(
          (e) => e.brand.name === categoryId || e.category.name === categoryId
        )
      );
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  }, [all_product, categoryId, categoryType]);

  useEffect(() => {
    try {
      // Фильтрация продуктов по типу
      setProductType(
        products.filter((e) => {
          // Проверка, содержит ли имя продукта ключевое слово
          const nameContainsKeyword =
            e.name.toLowerCase().includes(categoryType.toLowerCase()) ||
            e.types?.name.toLowerCase().includes(categoryType.toLowerCase()) ||
            e.covers?.name.toLowerCase().includes(categoryType.toLowerCase()) ||
            e.materials?.name
              .toLowerCase()
              .includes(categoryType.toLowerCase());
          return nameContainsKeyword;
        })
      );
      setCurrentPage(1)
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  }, [products, categoryType]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[currentPage])

  useEffect(() => {
    // Обновление newProducts при изменении productType
    setNewProducts(productType);
    setLoadingProducts(false);
  }, [productType]);

  const handleChange = (value) => {
    setCheck(value);
  };
  const productsPerPage = 18;
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = newProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  if (loadingProducts) {
    return (
      <div>
        <Header />
        <div className="loader">
          <ClipLoader />
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div className="err">
        К сожалению это действие временно не работает, пожалуйста вернитесь чуть
        позже
      </div>
    );
  } else {
    const filterProducts = (value) => {
      setCurrentPage(1);
      setNewProducts(value);
    };


    return (
      <div>
        <Header check={check} />
        <div className="go_home_main">
          <Link to="/">
            <h4 className="go_home">На главную</h4>
          </Link>
        </div>
        <div className="products_main">
          <div className="products_filter">
            <Filter products={productType} onChange={filterProducts} />
          </div>
          <div className="products_title_main">
            <h2 className="caatlog_title">{categoryId}</h2>
            <div className="products_all">
              {/* Проверяем, загрузились ли данные и нет ли ошибок */}
              {!loadingProducts && !error ? (
                <>
                  {/* Проверяем, есть ли продукты */}
                  {currentProducts.length > 0 ? (
                    // Отображаем продукты
                    currentProducts.map((el, i) => (
                      <ProductsItem
                        key={i}
                        id={el.id}
                        image={el.pictures[0].img}
                        title={el.name}
                        article={el.vendor_code}
                        price={el.price}
                        onChange={handleChange}
                      />
                    ))
                  ) : (
                    // Если продуктов нет, отображаем сообщение "Ничего не найдено"
                    <div className="no_products_container">
                      <h3 className="no_products">Ничего не найдено</h3>
                    </div>
                  )}
                </>
              ) : (
                // Отображаем индикатор загрузки, если данные еще загружаются, или сообщение об ошибке, если загрузка не удалась
                <div className="loader">
                  {loadingProducts ? <ClipLoader /> : "Ошибка загрузки данных"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Предыдущая
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentProducts.length < productsPerPage}
          >
            Следующая
          </button>
        </div>
      </div>
    );
    
    
  }
}

export default CategoryType;
