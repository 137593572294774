import React, { createContext, useState, useEffect } from "react";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {
  const [all_product, setAll_product] = useState([]);
  const [hitsProducts, setHitProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Состояние загрузки данных

  useEffect(() => {
    fetch('https://api.frank.kz/api/products')
      .then((response) => response.json())
      .then((data) => { 
        setAll_product(data.products); 
        setHitProducts(data.hits);
        setLoading(false); // Устанавливаем состояние загрузки в false после получения данных
      })
      .catch(error => {
        console.error('Ошибка загрузки данных:', error);
        setLoading(false); // Если произошла ошибка, также устанавливаем состояние загрузки в false
      });
  }, []);

  const contextValue = { all_product, hitsProducts, loading }; // Добавляем состояние загрузки в контекст
  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
